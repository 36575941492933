<template>
	<div id="data-sync-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Sincronizzazione dati
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<button type="button" class="btn btn-secondary w-100" @click="downloadData()">
				Aggiorna dati in cache
			</button>

			<hr>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="annotationsToBeSynced.length == 0" @click="syncAnnotations()">
				Segnalazioni ({{ annotationsToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="annotationMarkAsCompletedOperationsToBeSynced.length == 0" @click="syncAnnotationMarkAsCompletedOperations()">
				Segnalazioni completate ({{ annotationMarkAsCompletedOperationsToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="maintenancesToBeSynced.length == 0" @click="syncMaintenances()">
				Interventi ({{ maintenancesToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="itemUpdateOperationsToBeSynced.length == 0" @click="syncItemUpdateOperations()">
				Aggiornamento oggetti ({{ itemUpdateOperationsToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="itemDeadlinesUpdateOperationsToBeSynced.length == 0" @click="syncItemDeadlinesUpdateOperations()">
				Aggiornamento scadenze ({{ itemDeadlinesUpdateOperationsToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-3" :disabled="labelReplacementOperationsToBeSynced.length == 0" @click="syncLabelReplacementOperations()">
				Sostituzioni etichette ({{ labelReplacementOperationsToBeSynced.length }})
			</button>

			<button type="button" class="btn btn-info w-100 mb-0" :disabled="itemCreationOperationsToBeSynced.length == 0" @click="syncItemCreationOperations()">
				Inserimento oggetti ({{ itemCreationOperationsToBeSynced.length }})
			</button>
		</div>

		<hr>

		<div class="card border-danger mb-3">
			<div class="card-body">
				<h4 class="card-title text-red">
					Elimina dati locali
				</h4>

				<p class="card-text">
					Attenzione, questa azione non può essere annullata!<br>
					Tutti i dati salvati sul dispositivo, <strong>sincronizzati e non</strong>, verranno eliminati.
				</p>

				<button type="button" class="btn btn-danger w-100" @click="deleteLocalData()">
					Elimina dati locali
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from 'localforage'

import AnnotationsRepository from '@/repositories/annotations-repository'
import DeadlinesByRegistryRepository from '@/repositories/deadlines-by-registry-repository'
import ItemsRepository from '@/repositories/items-repository'
import ItemCreationOperationsRepository from '@/repositories/item-creation-operations-repository'
import ItemUpdateOperationsRepository from '@/repositories/item-update-operations-repository'
import ItemDeadlinesUpdateOperationsRepository from '@/repositories/item-deadlines-update-operations-repository'
import LabelReplacementOperationsRepository from '@/repositories/label-replacement-operations-repository'
import MaintenanceEnablementOperationsRepository from '@/repositories/maintenance-enablement-operations-repository'
import MaintenancesRepository from '@/repositories/maintenances-repository'
import RegistriesRepository from '@/repositories/registries-repository'
import ObjectFeaturesRepository from '@/repositories/object-features-repository'
import ObjectTypesRepository from '@/repositories/object-types-repository'
import FireClassesRepository from '@/repositories/fire-classes-repository'
import AnnotationMarkAsCompletedOperationsRepository from "@/repositories/annotation-mark-as-completed-operations-repository"
import { currentDate } from '@/utils/datetime-utils'

let annotationsRepository = new AnnotationsRepository()
let deadlinesByRegistryRepository = new DeadlinesByRegistryRepository()
let itemsRepository = new ItemsRepository()
let itemCreationOperationsRepository = new ItemCreationOperationsRepository()
let itemUpdateOperationsRepository = new ItemUpdateOperationsRepository()
let itemDeadlinesUpdateOperationsRepository = new ItemDeadlinesUpdateOperationsRepository()
let labelReplacementOperationsRepository = new LabelReplacementOperationsRepository()
let maintenanceEnablementOperationsRepository = new MaintenanceEnablementOperationsRepository()
let maintenancesRepository = new MaintenancesRepository()
let registriesRepository = new RegistriesRepository()
let objectFeaturesRepository = new ObjectFeaturesRepository()
let objectTypesRepository = new ObjectTypesRepository()
let fireClassesRepository = new FireClassesRepository()
let annotationMarkAsCompletedOperationsRepository = new AnnotationMarkAsCompletedOperationsRepository()

export default {
	name: "DataSyncView",
	data () {
		return {
			loading: false,
			annotations: [],
			annotationMarkAsCompletedOperations: [],
			itemCreationOperations: [],
			itemUpdateOperations: [],
			itemDeadlinesUpdateOperations: [],
			labelReplacementOperations: [],
			maintenances: [],
			user: null,
		}
	},
	computed: {
		annotationsToBeSynced () { return this.annotations.filter(a => a.synced_at == null) },
		itemCreationOperationsToBeSynced () { return this.itemCreationOperations.filter(ico => ico.synced_at == null) },
		itemUpdateOperationsToBeSynced () { return this.itemUpdateOperations.filter(iluo => iluo.synced_at == null) },
		itemDeadlinesUpdateOperationsToBeSynced () { return this.itemDeadlinesUpdateOperations.filter(iduo => iduo.synced_at == null) },
		labelReplacementOperationsToBeSynced () { return this.labelReplacementOperations.filter(lro => lro.synced_at == null) },
		maintenancesToBeSynced () { return this.maintenances.filter(m => m.synced_at == null) },
		annotationMarkAsCompletedOperationsToBeSynced () { return this.annotationMarkAsCompletedOperations.filter(amco => amco.synced_at == null) },
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			annotationsRepository.getAll().then(annotations => this.annotations = annotations),
			itemCreationOperationsRepository.getAll().then(itemCreationOperations => this.itemCreationOperations = itemCreationOperations),
			itemUpdateOperationsRepository.getAll().then(itemUpdateOperations => this.itemUpdateOperations = itemUpdateOperations),
			itemDeadlinesUpdateOperationsRepository.getAll().then(itemDeadlinesUpdateOperations => this.itemDeadlinesUpdateOperations = itemDeadlinesUpdateOperations),
			labelReplacementOperationsRepository.getAll().then(labelReplacementOperations => this.labelReplacementOperations = labelReplacementOperations),
			maintenancesRepository.getAll().then(maintenances => this.maintenances = maintenances),
			annotationMarkAsCompletedOperationsRepository.getAll().then(annotationMarkAsCompletedOperations => this.annotationMarkAsCompletedOperations = annotationMarkAsCompletedOperations),
		]).finally(() => this.loading = false)
	},
	methods: {
		downloadData () {
			this.loading = true

			Promise.all([
				annotationsRepository.replaceAllAssignedFromRemote(),
				registriesRepository.replaceAllFromRemote(),
				registriesRepository.replaceAllForAssignedItemDeadlinesFromRemote(),
				itemsRepository.replaceAllFromRemote(),
				deadlinesByRegistryRepository.getByDate(currentDate()),
				objectFeaturesRepository.replaceAllFromRemote(),
				objectTypesRepository.replaceAllFromRemote(),
				fireClassesRepository.replaceAllFromRemote(),
			]).finally(() => this.loading = false)
		},
		syncAnnotations () {
			this.loading = true

			annotationsRepository.syncAll()
				.then(annotations => this.annotations = annotations)
				.finally(() => this.loading = false)
		},
		syncItemCreationOperations () {
			this.loading = true

			itemCreationOperationsRepository.syncAll()
				.then(itemCreationOperations => this.itemCreationOperations = itemCreationOperations)
				.finally(() => this.loading = false)
		},
		syncItemUpdateOperations () {
			this.loading = true

			itemUpdateOperationsRepository.syncAll()
				.then(itemUpdateOperations => this.itemUpdateOperations = itemUpdateOperations)
				.finally(() => this.loading = false)
		},
		syncItemDeadlinesUpdateOperations () {
			this.loading = true

			itemDeadlinesUpdateOperationsRepository.syncAll()
				.then(itemDeadlinesUpdateOperations => this.itemDeadlinesUpdateOperations = itemDeadlinesUpdateOperations)
				.finally(() => this.loading = false)
		},
		syncLabelReplacementOperations () {
			this.loading = true

			labelReplacementOperationsRepository.syncAll()
				.then(labelReplacementOperations => this.labelReplacementOperations = labelReplacementOperations)
				.finally(() => this.loading = false)
		},
		syncMaintenances () {
			this.loading = true

			maintenancesRepository.syncAll()
				.then(maintenances => this.maintenances = maintenances)
				.finally(() => this.loading = false)
		},
		syncAnnotationMarkAsCompletedOperations () {
			this.loading = true

			annotationMarkAsCompletedOperationsRepository.syncAll()
				.then(annotationMarkAsCompletedOperations => this.annotationMarkAsCompletedOperations = annotationMarkAsCompletedOperations)
				.finally(() => this.loading = false)
		},
		deleteLocalData () {
			let confirmation = confirm("Per procedere è richiesta una conferma. Continuare?")

			if (!confirmation) {
				return
			}

			this.loading = true

			Promise.all([
				annotationsRepository.deleteAll().then(annotations => this.annotations = annotations),
				annotationsRepository.deleteAllAssigned(),
				deadlinesByRegistryRepository.deleteAll(),
				itemsRepository.deleteAll(),
				itemCreationOperationsRepository.deleteAll().then(itemCreationOperations => this.itemCreationOperations = itemCreationOperations),
				itemUpdateOperationsRepository.deleteAll().then(itemUpdateOperations => this.itemUpdateOperations = itemUpdateOperations),
				itemDeadlinesUpdateOperationsRepository.deleteAll().then(itemDeadlinesUpdateOperations => this.itemDeadlinesUpdateOperations = itemDeadlinesUpdateOperations),
				labelReplacementOperationsRepository.deleteAll().then(labelReplacementOperations => this.labelReplacementOperations = labelReplacementOperations),
				maintenanceEnablementOperationsRepository.deleteAll(),
				maintenancesRepository.deleteAll().then(maintenances => this.maintenances = maintenances),
				registriesRepository.deleteAll(),
				objectFeaturesRepository.deleteAll(),
				objectTypesRepository.deleteAll(),
				fireClassesRepository.deleteAll(),
				annotationMarkAsCompletedOperationsRepository.deleteAll(),
			]).finally(() => this.loading = false)
		},
	}
}
</script>