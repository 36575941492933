<template>
	<div id="items-create-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Assegna cliente
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="card mb-3">
				<div class="card-body">
					<div class="form-group mb-3">
						<label for="registry_id" class="control-label">Anagrafica</label>
						<select name="registry_id" class="form-control" v-model="registry_id">
							<option v-for="registry in registries" :key="registry.id" :value="registry.id">{{ registry.name }}</option>
						</select>
					</div>

					<div class="form-group mb-3">
						<label for="registry_location_id" class="control-label">Sede</label>
						<select name="registry_location_id" class="form-control" v-model="registry_location_id">
							<option v-for="registryLocation in registryLocations" :key="registryLocation.id" :value="registryLocation.id">
								{{ registryLocation.address }}
								-
								{{ registryLocation.zip }}
								{{ registryLocation.city }}
								({{ registryLocation.province_code }})
							</option>
						</select>
					</div>

					<button class="btn btn-primary w-100" type="button" @click="assignToMaintainer()" :disabled="registry_id == null || registry_location_id == null">
						Assegna
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import localforage from "localforage"

import RegistriesRepository from '@/repositories/registries-repository'

let registriesRepository = new RegistriesRepository()

export default {
	name: "MaintainerAssignmentView",
	data () {
		return {
			loading: false,
			registries: [],
			registry_id: null,
			registry_location_id: null,
		}
	},
	computed: {
		selectedRegistry () {
			return this.registries.find(r => r.id == this.registry_id)
		},
		registryLocations () {
			return this.selectedRegistry ? this.selectedRegistry.registry_locations : []
		},
	},
	watch: {
		selectedRegistry () {
			this.registry_location_id = null
		},
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			registriesRepository.getAll().then(registries => this.registries = registries),
		]).finally(() => this.loading = false)
	},
	methods: {
		assignToMaintainer () {
			this.loading = true

			Promise.all([
				axios.post("/item-deadlines/assign-to-maintainer", {registry_id: this.registry_id, registry_location_id: this.registry_location_id}),
				axios.post("/annotations/assign-to-maintainer", {registry_id: this.registry_id, registry_location_id: this.registry_location_id})
			]).finally(() => {
				this.loading = false
				this.$router.push({name: 'home'})
			})
		},
	},
}
</script>