<template>
	<div id="items-index-view" class="container">
		<div class="mb-3" style="display: flex; flex-direction: row; align-items: center;">
			<h1 class="my-0">
				Oggetti inseriti
			</h1>
		</div>

		<div v-if="loading">
			<div class="card mb-3">
				<div class="card-body">
					Caricamento in corso...

					<div class="progress mt-1">
						<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<router-link :to="{name: 'items.create'}" class="btn btn-primary mb-3 w-100">
				Inserisci oggetto
			</router-link>

			<div v-if="items.length == 0">
				<div class="card mb-3">
					<div class="card-body">
						Nessuna oggetto inserito.
					</div>
				</div>
			</div>
			<div v-else>
				<button class="btn btn-info w-100 mb-3" type="button" @click="syncItemCreationOperations()" :disabled="itemsToBeSynced.length == 0">
					Sincronizza oggetti inseriti ({{ itemsToBeSynced.length }})
				</button>
				<div class="card mb-3">
					<div class="table-responsive">
						<table class="table table-bordered table-vmiddle mb-0">
							<thead>
								<tr>
									<th class="text-nowrap">Anagrafica</th>
									<th class="text-nowrap">Tipologia</th>
									<th class="text-nowrap">Caratteristiche</th>
									<th class="text-nowrap">Classe d'incendio</th>
									<th class="text-nowrap">Anno</th>
									<th class="text-nowrap">Quantità</th>
									<th class="text-nowrap" width="1%">Sincronizzato</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in items" :key="item.created_at" :class="{'sync-status--success': item.synced_at != null}">
									<td class="text-nowrap">
										<div v-if="item.registry">
											{{ item.registry.name }}
										</div>
										<div class="text-muted" v-if="item.registry_location">
											{{ item.registry_location.address }}
											<br>
											{{ item.registry_location.zip }}
											{{ item.registry_location.city }}
											({{ item.registry_location.province_code }})
										</div>
									</td>
									<td class="text-nowrap">
										<span v-if="item.object_type">
											{{ item.object_type.name }}
										</span>
										<strong>{{ item.registration_number }}</strong>
									</td>
									<td class="text-nowrap">{{ item.object_features.map(feature => feature.name).join(", ") }}</td>
									<td class="text-nowrap">{{ item.fire_class ? item.fire_class.code : "" }}</td>
									<td class="text-nowrap">{{ item.date }}</td>
									<td class="text-nowrap">{{ item.quantity }} {{ item.measure_unit }}</td>
									<td class="text-nowrap text-center">
										<span v-if="item.synced_at">Sì</span>
										<span v-else>No</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import localforage from "localforage"
import { currentDate } from "@/utils/datetime-utils";

import ItemCreationOperationsRepository from '@/repositories/item-creation-operations-repository'
import RegistriesRepository from '@/repositories/registries-repository'
import ObjectFeaturesRepository from '@/repositories/object-features-repository'
import ObjectTypesRepository from '@/repositories/object-types-repository'
import FireClassesRepository from '@/repositories/fire-classes-repository'

let itemCreationOperationsRepository = new ItemCreationOperationsRepository()
let registriesRepository = new RegistriesRepository()
let objectFeaturesRepository = new ObjectFeaturesRepository()
let objectTypesRepository = new ObjectTypesRepository()
let fireClassesRepository = new FireClassesRepository()

export default {
	name: "ItemsIndexView",
	data () {
		return {
			loading: false,
			user: null,
			registries: [],
			objectFeatures: [],
			objectTypes: [],
			fireClasses: [],
			items: [],
		}
	},
	computed: {
		itemsToBeSynced () {
			return this.items.filter(i => i.synced_at == null)
		}
	},
	mounted () {
		this.loading = true

		Promise.all([
			localforage.getItem("USER").then(user => this.user = user || null),
			registriesRepository.getAllForAssignedItemDeadlines().then(registries => this.registries = registries),
			objectFeaturesRepository.getAll().then(objectFeatures => this.objectFeatures = objectFeatures),
			objectTypesRepository.getAll().then(objectTypes => this.objectTypes = objectTypes),
			fireClassesRepository.getAll().then(fireClasses => this.fireClasses = fireClasses),
			itemCreationOperationsRepository.getAll().then(items => {
				this.items = items.filter(item => item.created_at.split(" ")[0] == currentDate())
				.sort((a, b) => a.created_at < b.created_at ? 1 : -1)
				.map(item => {
					item.registry = this.registries.find(r => r.id == item.registry_id)
					item.registry_location = item.registry.registry_locations.find(rl => rl.id == item.registry_location_id)
					item.object_type = this.objectTypes.find(ot => ot.id == item.object_type_id)
					item.object_features = this.objectFeatures.filter(of => item.object_features_ids.includes(of.id))
					item.fire_class = this.fireClasses.find(fc => fc.id == item.fire_class_id)
					return item
				})
			}),
		]).finally(() => this.loading = false)
	},
	methods: {
		syncItemCreationOperations () {
			this.loading = true

			itemCreationOperationsRepository.syncAll()
				.then(itemCreationOperations => this.items = itemCreationOperations)
				.finally(() => this.loading = false)
		},
	}
}
</script>

<style scoped>
	tr.sync-status--success th,
	tr.sync-status--success td {
		background-color: lightgreen;
	}
</style>