import axios from "axios";
import localforage from "localforage";
import { currentDatetime } from "@/utils/datetime-utils";

export default class ItemDeadlinesUpdateOperationsRepository {

	constructor () {
		this.repositoryNamespace = "ITEM_DEADLINES_UPDATE_OPERATIONS"
	}

	async getAll () {
		return localforage.getItem(this.repositoryNamespace).then(itemDeadlinesUpdateOperations => itemDeadlinesUpdateOperations ?? [])
	}

	async create (itemDeadlinesUpdateOperationData) {
		let itemDeadlinesUpdateOperation = {
			uuid: itemDeadlinesUpdateOperationData.uuid,
			next_revision: itemDeadlinesUpdateOperationData.next_revision,
			next_testing: itemDeadlinesUpdateOperationData.next_testing,
			next_control: itemDeadlinesUpdateOperationData.next_control,
			synced_at: null,
			created_at: currentDatetime(),
		}

		if (navigator.onLine) {
			let requestParams = {
				uuid: itemDeadlinesUpdateOperationData.uuid,
				next_revision: itemDeadlinesUpdateOperationData.next_revision,
				next_testing: itemDeadlinesUpdateOperationData.next_testing,
				next_control: itemDeadlinesUpdateOperationData.next_control,
			}

			try {
				await axios.post("/item-deadlines-update-operations/create", requestParams)
				itemDeadlinesUpdateOperation.synced_at = currentDatetime()
			} catch (error) {
				// Some errors are handled with an interceptor
			}
		}

		let existingItemDeadlinesUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []
		existingItemDeadlinesUpdateOperations.push(itemDeadlinesUpdateOperation)

		return localforage.setItem(this.repositoryNamespace, existingItemDeadlinesUpdateOperations)
	}

	async syncAll () {
		let existingItemDeadlinesUpdateOperations = await localforage.getItem(this.repositoryNamespace) ?? []

		for (let i = 0; i < existingItemDeadlinesUpdateOperations.length; i++) {
			let itemDeadlinesUpdateOperation = existingItemDeadlinesUpdateOperations[i]

			if (navigator.onLine && itemDeadlinesUpdateOperation.synced_at == null) {
				let requestParams = {
					uuid: itemDeadlinesUpdateOperation.uuid,
					next_revision: itemDeadlinesUpdateOperation.next_revision,
					next_testing: itemDeadlinesUpdateOperation.next_testing,
					next_control: itemDeadlinesUpdateOperation.next_control,
				}

				try {
					await axios.post("/item-deadlines-update-operations/create", requestParams)
					existingItemDeadlinesUpdateOperations[i].synced_at = currentDatetime()
				} catch (error) {
					// Some errors are handled with an interceptor
				}
			}
		}

		return localforage.setItem(this.repositoryNamespace, existingItemDeadlinesUpdateOperations)
	}

	// --------------------------------------------------

	async deleteAll () {
		return localforage.setItem(this.repositoryNamespace, [])
	}
}